<template>
  <div class="">
    <div class="tihsi font14">
      offer立即<span @click="$router.push('/downapp')">下载APP</span
      >,随时随地找工作，实时查看面试进度、接收面试通知和Offer管理 。
    </div>
    <!-- 无记录 -->
    <div class="nodata" v-if="!userList.length">
      <img src="../../assets/images/common/search-no.png" />
      <div class="font18" v-if="!loading">当前无offer记录</div>
      <div class="font18" v-if="loading">加载中</div>
    </div>
    <!-- 有记录 -->
    <div class="">
      <el-card class="positionlist">
        <div
          class="box-card p-list"
          v-for="(itemli, indexes) in userList"
          :key="indexes"
        >
          <div class="p-listbox">
            <div class="pl-left font14">
              <div class="l-one">
                <div class="font16">
                  {{ itemli.companyJobName }}
                  <span class="biaoqian1">Free</span>
                  <span class="biaoqian2" v-if="itemli.status == '0'"
                    >待回复</span
                  >
                  <span class="biaoqian4" v-if="itemli.status == '1'"
                    >已接受</span
                  >
                  <span class="biaoqian4" v-if="itemli.status == '2'"
                    >已拒绝</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '3'"
                    >公司待同意</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '4'"
                    >待重新接受</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '5'"
                    >公司拒绝</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '6'"
                    >后台已发送简历</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '7'"
                    >已超时</span
                  >
                </div>
              </div>
              <div class="l-two">
                offer薪资：<span>{{ (itemli.offerSalary / 1000) | numFilter }}K</span
                >预估税前薪资：<span>{{ itemli.offerSalary }}</span>
              </div>
              <div class="l-three">到岗时间：{{ itemli.joinDate }}</div>
            </div>
            <div class="pl-right font14">
              <!-- <div class="synum">剩余招聘:<span>1</span>人</div> -->
              <div class="time">{{ itemli.shortName }}</div>
              <div class="czbtn">
                <img
                  v-if="itemli.companyAvatar"
                  :src="
                    'https://freemen.work/user/file/view/' +
                    itemli.companyAvatar
                  "
                />
                <img v-else src="../../assets/logo.png" />
              </div>
            </div>
          </div>
          <!-- <div class="btn"  v-if="itemli.status=='0' || itemli.status=='4'">
						<el-button type="success" v-if="itemli.status=='0'" @click="saveOffer(itemli,1)">接受offer</el-button>
						<el-button type="primary" v-if="itemli.status=='0'" @click="saveOffer(itemli,2)">拒绝offer</el-button>
						<el-button type="success" v-if="itemli.status=='4'" @click="saveOffer(itemli,3)">重新接受</el-button>
					</div> -->
        </div>
        <div class="nextpage" @click="getMoreList" v-if="moreBtn">
          点击加载更多
        </div>
      </el-card>

      <!-- <div class="paginate" style="position: relative;">
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page="pageInfo.pageNum"
					:page-size="pageInfo.pageSize"
					layout="total, prev, pager, next, jumper"
					:total="pageInfo.count"
				></el-pagination>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      userList: [],
      moreBtn: false,
      loading: false,
    };
  },
  created() {
    this.getOfferList();
  },
  mounted() {},
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    getOfferList() {
      //获取面试列表
      this.loading = true;

      this.$api.getOfferList("get", this.pageInfo).then((res) => {
        this.userList = this.userList.concat(res.data);
        this.loading = false;

        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getPostRecord();
    },
    saveOffer(data, type) {
      this.$api
        .saveOffer("post", { userOfferId: data.id, status: type })
        .then(() => {
          this.userList = [];
          this.pageInfo.pageNo = 1;
          this.getOfferList();
        });
    },
    handleCurrentChange() {
      // val
      // this.fetchData(val);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
/deep/.el-card__body {
  padding: 0px 0;
}
.tihsi {
  background-color: #444c5f;
  color: #abb0ba;
  padding: 0px 30px;
  line-height: 44px;
  span {
    color: #00bcff;
  }
}
.paginate {
  margin: 130px 0 100px 0 !important;
  .el-pagination {
    padding: 0 !important;
  }
}
.positionlist {
  margin-top: 20px;
  .p-list:hover {
    // transform: scale(1.01);
    background-color: rgba(0, 0, 0, 0.05);
  }
  .p-list {
    // margin-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;
    padding: 20px;
    .p-listbox {
      display: flex;
      align-items: center;
      // padding-bottom: 20px;
      .pl-left {
        display: flex;
        flex: 1;
        // align-items: center;
        flex-direction: column;
        line-height: 2.2;
        .l-one {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          div {
            color: #111111;
            font-weight: 600;
            // span {
            // 	color: #ff8400;

            // }
            .biaoqian1 {
              background-color: #e9f9ff;
              color: #00bcff;
              padding: 1px 10px;
              margin: 0 10px;
              font-size: 14px;
              font-weight: 500;
            }
            .biaoqian2 {
              background-color: #feecde;
              color: #ff8400;
              padding: 1px 10px;
              margin-left: 10px;
              font-size: 14px;
              font-weight: 500;
            }
            .biaoqian3 {
              background-color: #feecde;
              color: #ff5400;
              padding: 1px 10px;
              margin-left: 10px;
              font-size: 14px;
              font-weight: 500;
            }
            .biaoqian4 {
              background-color: #f3f3f3;
              color: #9f9f9f;
              padding: 1px 10px;
              margin-left: 10px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .l-two {
          color: #666666;
          span {
            color: #111111;
            margin-right: 20px;
          }
        }
        .l-three {
          color: #999999;
        }
      }
      .pl-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        color: #666666;
        // div{
        // 	display: flex;
        // 	align-items: center;
        // 	justify-content: center;
        // 	flex: 1;
        // }
        .time {
          color: #111111;
          width: 150px;
        }
        .synum {
          span {
            color: #111111;
          }
        }
        .czbtn {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
.nodata {
  background-color: #ffffff;
  // box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 96px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
</style>
